import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

//  引入需要的语言包 
import en from "@/lang/en.json";
import zh from "@/lang/zh.json";
import ru from "@/lang/ru.json";
import ae from "@/lang/ar.json";
import bn from "@/lang/bn.json";
import cs from "@/lang/cs.json";
import da from "@/lang/da.json";
import de from "@/lang/de.json";
import el from "@/lang/el.json";
import it from "@/lang/it.json";
import jp from "@/lang/ja.json";
import kr from "@/lang/ko.json";
import nl from "@/lang/nl.json";
import pl from "@/lang/pl.json";
import ro from "@/lang/ro.json";
import sk from "@/lang/sk.json";
import tr from "@/lang/tr.json";
import ka from "@/lang/ka.json";
import az from "@/lang/az.json";
import iw from "@/lang/iw.json";
import fa from "@/lang/fa.json"; // 波斯
import es from "@/lang/es.json"; //西班牙
import fi from "@/lang/fi.json";//芬兰语
import fr from "@/lang/fr.json"; //法语
import hu from "@/lang/hu.json";//匈牙利语
import pt from "@/lang/pt.json"; //葡萄牙
import sv from "@/lang/sv.json"; //瑞典语

let json = JSON.parse(localStorage.getItem('store'))||{}
// 本地语言环境获取
const lang = json.language || 'en-us'

const i18n = new VueI18n({
  locale: lang, // 语言标识
  fallbackLocale: "en-us", // 失败时默认语言
  silentTranslationWarn: true, // 设置为true 会屏蔽翻译在控制台报出的警告
  messages: {
    'en-us': en,
    'zh-cn': zh,
    'ru-ru': ru,
    'ar-ae': ae,
    'bn-bn': bn, 
    'cs-cz': cs,
    'da-dk': da,
    'de-de': de,
    'el-gr': el,
    'es-es': es,
    'it-it': it,
    'ja-jp': jp,
    'ko-kr': kr,
    'nl-nl': nl,
    'pl-pl': pl,
    'ro-ro': ro,
    'sk-sk': sk,
    'tr-tr': tr,
    'ka-ka': ka,
    'az-az': az,
    'he-il': iw,
    'fa-ir': fa,
    'pt-pt': pt,
    'fi-fi': fi,
    'fr-fr': fr,
    'hu-hu': hu,
    'sv-se': sv,
  },
});

export default i18n;

